import Glide from '../glide/glide'

window.onload = function () {

  /* show / hide main nav
  -------------------------------------------------- */
  var btnnav = document.querySelector('.js-togglemenu');
  var mobilenav = document.querySelector('.js-navwrapper');
  var body = document.querySelector('.js-body');

  btnnav.onclick = function () {
    btnnav.classList.toggle('active');
    mobilenav.classList.toggle('active');
    body.classList.toggle('active');
  }

  /* handle click on top navigation
-------------------------------------------------- */
  let navigationButtons = document.querySelectorAll('.navbar .nav_icon');
  navigationButtons.forEach(function(navigationButton) {
    navigationButton.addEventListener('click', function () {
      // set current 'active' class
      if (navigationButton.classList.contains('active'))
      {
        navigationButton.classList.remove('active');
      } else {
        // remove all 'active' classes
        navigationButtons.forEach(function(navigationButton) {
          navigationButton.classList.remove('active')
        })
        // set active
        navigationButton.classList.add('active');
      }
    });
  });

  /* glides Slider
  -------------------------------------------------- */
  var kachelslider = new Glide(".js-slider", {
    gap: 28,
    type: 'carousel',
    rewind: true,
    perView: 6,
    autoplay: 5000,
    focusAt: 'center',
    swipeThreshold: 20,
    peek: {
      before: 100,
      after: 100
    },
    breakpoints: {
      1600: {
        perView: 4,
        peek: {
          before: 80,
          after: 80
        },
      },
      1200: {
        perView: 2,
        peek: {
          before: 60,
          after: 60
        },
      },
      768: {
        perView: 1,
        peek: {
          before: 40,
          after: 40
        },
      }
    }
  });
  kachelslider.mount();

  /* Slider */
  // let slides = document.querySelectorAll('#heroSlideshow .slide');
  // let currentSlide = 0;
  // let slideInterval = setInterval(nextSlide, 3000);

  // function nextSlide() {
  //   slides[currentSlide].className = 'header-image slider_item slide';
  //   currentSlide = (currentSlide + 1) % slides.length;
  //   slides[currentSlide].className = 'header-image slider_item slide showing';
  // }

};

window.onscroll =function() {
  // remove navigation popus on scroll
  let navigationButtons = document.querySelectorAll('.navbar .nav_icon');
  navigationButtons.forEach(function(navigationButton) {
    navigationButton.classList.remove('active')
  });
}